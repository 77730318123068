*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

:root {
  --primary-font: 'Actor', sans-serif;
  --secondary-font: 'Gentium Plus', serif;
  --titles-font: 'Forum', cursive;
  --primary-background-color: #f9f9f9;
}

a:link {
  color: black; 
  text-decoration: none;
}

a:visited {
  color: black;
  text-decoration: none;
}

ul li,
ol li {
  list-style: none;
}

ul li a:link,
ol li a:link {
  color: black;
  text-decoration: none;
}

ul li a:visited,
ol li a:visited {
  color: black; 
  text-decoration: none;
}

::-webkit-scrollbar {
  overflow-y: scroll;
  width: 10px;
  background: var(--primary-background-color);
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-background-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #faeee5; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.336);
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -10;
}

.App {
  width: 100%;
  height: 100%;
  background-color: var(--primary-background-color);
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  scrollbar-gutter: stable;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: bottom;
  background-color: #faeee5;
  z-index: 10;
  overflow: hidden;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top;
  background-color: #faeee5;
  z-index: 10;
  overflow: hidden;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  scrollbar-gutter: stable;
}

button {
  outline: none;
  border: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

a, li {
  outline: none;
  list-style-type: none;
  text-decoration: none;
}

body {
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

button {
  background-color: transparent;
  border: none; 
  box-shadow: none; 
  padding: 0; 
}

.gallery-body {
  overflow-y: visible;
  position: relative;
  font-family: var(--primary-font), var(--secondary-font);
  opacity: 1;
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  transition: opacity .8s ease-in-out;
}

.gallery-body.hide {
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
}

#fadeContent {
  opacity: 0;
  overflow: hidden;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.main-body-gallery {
  margin-top: 15vh;
  width: 100%;
}

.home-container {
  height: 100vh;
  width: 100vw;
  font-family: var(--titles-font), var(--secondary-font);
  background-color: var(--primary-background-color);
  overflow: hidden;
  scrollbar-gutter: stable;
}

.header-main {
  position: relative;
  width: 100vw;
  z-index: 3;
  scrollbar-gutter: stable;
  padding-top: 3%;
  font-family: var(--primary-font), var(--secondary-font);
  font-size: .8em;
  font-weight: 300;
  height: 10vh;
  background-color: var(--primary-background-color);
  padding-bottom: 3%;
}

.btn-language {
  cursor: pointer;
}

.header-problem {
  background: transparent;
}

.header {
  position: relative;
  padding: 0% 3% 0 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-buttons-container {
  display: block;
  padding: 0;
  margin: 0;
}

#menu-button {
  position: relative;
  cursor: pointer;
}

#menu-button:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom left;
  transition: transform 0.70s ease-out;
}

#menu-button:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#explore-button {
  cursor: pointer;
  opacity: 0.6;
}

.name-container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 30vh;
}

.main-name {
  font-family: var(--titles-font), var(--secondary-font);
  padding-bottom: .2em;
  font-weight: 500;
  font-size: 3em;
  z-index: 10;
}

.periods {
  height: fit-content;
  width: 100%;
  padding-top: 7%;
}

.periodnav {
  padding: 0% 7% 0 7%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--primary-font), var(--secondary-font);
  font-size: 1em;
  font-weight: 500;
}

.period {
  cursor: pointer;
  transition: opacity 2s ease-in-out, transform 1.8s ease-in-out;
}

/*Paintings Container*/
.container-paintings {
  margin-top: 5%;
  width: 100%;
}

#paintingscontainer { 
  margin: 0% 5% 0 5%;
  width: 90%; 
  position: relative;
  font-family: var(--primary-font), var(--secondary-font);
}

.dates {
  width: fit-content;
  padding: 0%;
  margin: 0%;
  margin-bottom: 3%;
  position: relative;
  opacity: 0.7;
  left: 5%;
  font-size: 1.5em;
  font-family: var(--primary-font), var(--secondary-font);
  font-weight: 300;
}

#date2 {
  width: fit-content;
  padding: 0%;
  margin: 0%;
  padding-top: 10%;
  padding-bottom: 4%;
  position: relative;
  opacity: 0.7;
  left: 80%;
  font-size: 150%;
}

#date3 {
  padding: 0%;
  margin: 0%;
  width: fit-content;
  padding-top: 10%;
  padding-bottom: 4%;
  position: relative;
  opacity: 0.7;
  left: 80%;
  font-size: 150%;
}

#imagegrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  row-gap: 2%;
  column-gap: 2%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.fade-in {
  opacity: 0;
  transform: translateY(10%);
  transition: transform 2s ease, opacity 2s ease-in;
}

.fade-in.appear {
  opacity: 1;
  transform: translate(0);
}

#imagegrid img {
  width: 100%;
  object-fit: cover;
}

#imagecontainer {
  cursor: pointer;
  position: relative;
  width: 220px;
  margin: 0%;
  padding: 0%;
}

.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e7e7e7, 0.6;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1.3s;
}

.image_overlay:hover {
  opacity: 1;
}

@keyframes opacityimages{
  from {
      opacity: 0;
  }to {
      opacity: 1;
  }
}

#datelines1{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#datelines2{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#datelines3{
  border: 0.4px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  margin-top: 10%;
  opacity: 0;
}

#datelines4{
  border: 0.4px solid rgb(0, 0, 0);
  border-radius: 1px;
  width: 98%;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 2%;
  left: 1%;
}

@keyframes growLines {
  0% {
    width: 0px;
    right: 3%;
    height: 1px;
    opacity: 0;
  }
  100% {
    width: 105%;
    right: 3%;
    height: 1px;
    opacity: 1;
  }
}

@keyframes growLinesTotal {
  0% {
    width: 0px;
    height: 1px;
    opacity: 0;
  }
  100% {
    width: 95%;
    height: 1px;
    opacity: 1;
  }
}

.fade-in img {
  opacity: 1;
  transition: opacity 1.5s ease;
}

.fade-in:hover img {
  opacity: 0.2;
}

/*imagegrid 2*/

#imagegrid2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  row-gap: 1%;
  column-gap: 1%;
  margin-top: 5%;
  margin-bottom: 5%;
}

#imagegrid2 img{
  width: 100%;
  object-fit: cover;
}

/*imagegrid 3*/

#imagegrid3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  row-gap: 3%;
  margin-bottom: 10%;
  column-gap: 3%;
}

#imagegrid3 img{
  width: 100%;
  object-fit: cover;
}

/* MENU */

.menu-container {
  font-family: var(--titles-font), var(--secondary-font);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: hidden;
  background-color: white;
  flex-direction: column;
  z-index: 5;
}

.menu-header {
  position: relative;
  display: flex;
  align-self: first baseline;
  padding-left: 3%;
  padding-top: 3%;
}

.menu-footer {
  width: 100%;
  position: absolute;
  bottom: 3%;
  padding: 0 3% 0 3%;
  font-family: var(--primary-font);
  font-size: .9em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-websites {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.close-menu-button {
  cursor: pointer;
  position: relative;
  transition: opacity 1s ease-in-out;
}

.close-menu-button:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom left;
  transition: transform 0.70s ease-out;
}

.close-menu-button:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.main-menu-container {
  margin: 5% 0 5% 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.effect-container {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.menu-division-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 15vh;
  justify-content: space-between;
}

.menu-name-container {
  position: absolute;
  background-color: var(--primary-background-color);
  opacity: .75;
  top: 50%;
  width: 35vw;
  height: 22vh;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  letter-spacing: 3px;
}

.menu-name-container-2 {
  position: absolute;
  opacity: .75;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  letter-spacing: 3px;
}

.menu-name {
  padding-bottom: .2em;
  font-weight: 600;
  font-size: 2em;
}

.menu-name-painter {
  font-weight: 500;
  font-size: 2em;
  opacity: .55;
}

.menu-buttons {
  cursor: pointer;
  font-size: 1.3em;
  font-weight: 500;
  font-family: Inter,sans-serif;
  height: fit-content;
  display: inline-block;
  position: relative;
  height: fit-content;
}

.menu-buttons:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.1px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom left;
  transition: transform 0.70s ease-out;
}

.menu-buttons:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* CAROUSEL */

#divisioncarousel1 {
  width: 100%;
  font-family: var(--primary-font), var(--secondary-font);
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: block;
  opacity: 0;
  z-index: 3;
  animation: fadeIn 2.2s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#carouselheading {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 3% 7% 0 7%;
}

#year {
  font-size: 2em;
  font-family: var(--primary-font), var(--secondary-font);
}

#images-container {
  position: absolute;
  top: 5%;
  z-index: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#buttons-container {
  position: relative;
  width: 100vw;
  display: flex;
  padding: 0% 7% 0 7%;
  flex-direction: row;
  justify-content: space-between;
  top: 40vh;
}

.hidden {
  opacity: 0;
}

#image-carousel {
  position: absolute;
  height: 84vh;
}

#numbering {
  position: absolute;
  top: 87vh;
}

#dimensions {
  position: absolute;
  font-family: var(--primary-font), var(--secondary-font);
  top: 80vh;
  left: 5vw;
  width: 13%;
}

#buttonback {
  margin-top: 1%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 1em;
  opacity: 0.4;
  height: fit-content;
}

#buttonback:hover {
  opacity: 1;
  transition: opacity 1.5s ease-out;
  -moz-transition: opacity 1.5s ease-out;
  -webkit-transition: opacity 1.5s ease-out;
  -o-transition: opacity 1.5s ease-out;
}

#buttonback:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom left;
  transition: transform 0.50s ease-out;
}

#buttonback:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#left {
  font-size: 30px;
  position: relative;
  top: 45%;
  opacity: 0.4; 
  height: fit-content;   
  transition: 0.3s ease-out;
  transition-delay: 0.01s;
  cursor: pointer;
  color: #000000;
}

#left:hover {
  opacity: 0.8;
  font-weight: 550;
  transition: weight 1s ease-out;
  -moz-transition: weight 1s ease-out;
  -webkit-transition: weight 1s ease-out;
  -o-transition: weight 1s ease-out;
  transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -webkit-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
}

#right {
  cursor: pointer;
  position: relative;
  margin: 0%;
  font-size: 30px;
  opacity: 0.4; 
  height: fit-content;   
  top: 45%;
  transition: 0.3s ease-out;
  transition-delay: 0.01s;
  color: #000000;
}

#right:hover {
  opacity: 0.8;
  font-weight: 550;
  transition: weight 1s ease-out;
  -moz-transition: weight 1s ease-out;
  -webkit-transition: weight 1s ease-out;
  -o-transition: weight 1s ease-out;
  transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -webkit-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
}

/* About */

.about-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-bio-container {
  margin: 0;
  padding: 0 6vw 0 3vw;
  width: 100%;
  position: relative;
  margin-top: 17vh;
  display: flex;
  flex-direction: row;
}

.biography-container {
  font-family: var(--primary-font), var(--secondary-font);
  justify-content: space-between;
  height: 60vh;
  margin-bottom: 10%;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}

.about-image-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  position: relative;
}

.image-container {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.about-image {
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
  position: relative;
  width: 100%;
  height: auto;
}

.email-container {
  width: fit-content;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-size: .9em;
  display: flex;
  align-items: flex-start;
  font-family: var(--titles-font), var(--secondary-font);
}

.bio-email {
  margin-top: 1%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: fit-content;
  transition: opacity 4s ease-in-out, transform 3.5s ease-in-out;
  font-size: 1.7em;
  font-weight: 500;
  display: inline-block;
  position: relative;
  height: fit-content;
}

.bio-email:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: .8px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom left;
  transition: transform 0.70s ease-out;
}

.bio-email:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.bio-title-container {
  width: fit-content;
  overflow: hidden;
  padding: 0;
  margin-bottom: 3%;
  display: flex;
  align-items: flex-start;
  text-transform: none;
}

.bio-title { 
  transition: opacity 4s ease-in-out, transform 3.5s ease-in-out;
  font-size: 1.7em;
  font-weight: 500;
  display: inline-block;
  position: relative;
  height: fit-content;
}

.bio-firstInfos {
  font-weight: 500;
  transition: opacity 3.5s ease-in-out, transform 3s ease-in-out;
}

.infos-conainer {
  font-family: var(--primary-font), var(--secondary-font);
  text-transform: uppercase;
  padding-top: 5%;
}

.about-exhibitions-container {
  display: flex;
  width: 100%;
  padding: 0 5% 0 5%;
  flex-direction: row;
  justify-content: flex-end;
}

.exhibitions {
  display: flex;
  flex-direction: row;
  width: 50%;
  position: relative;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top: 5%;
  align-self: flex-end;
  font-family: var(--titles-font), var(--secondary-font);
}

.exhibitions-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 5%;
}

.exhibitions-title-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  justify-content: flex-start;
}

.exhibitions-title {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
  font-size: 1.5em;
  font-weight: 500;
}

.titles-appear {
  opacity: 1;
  transform: translateY(0%);
}

.excibitions-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  margin-bottom: 5%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.text-appear {
  opacity: 1;
}

.excibition-box-date {
  display: flex;
  align-self: flex-start;
  font-size: 1em;
  opacity: 0.6;
}

.excibition-box-info {
  width: 70%;
}

.excibition-box-info li {
  padding-bottom: 6px;
}

#excibition-line-braker{
  display: none;
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#excibition-lines1{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#excibition-lines2{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#excibition-lines3{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 5%;
  opacity: 0;
}

#excibition-lines4{
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-top: 1%;
  margin-bottom: 2%;
  opacity: 0;
}

/* Contact */

.contact-container {
  margin: 5vh 0 10vh 0;
  align-self: flex-start;
  padding: 0 0 0 5%;
  width: 50%;
  height: 15vh;
  display: block;
  display: flex;
  flex-direction: column;  
  justify-content: space-between;
  align-items: flex-start;
  text-transform: none;
  font-family: var(--titles-font), var(--secondary-font);
}

.contact-infos {
  font-size: 1.5em;
  font-weight: 500;  
}

/* Events */

.future-events {
  overflow-y: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  padding-top: 10vh;
  padding-left: 4%;
  background-color: var(--primary-background-color);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title-future-events {
  font-family: var(--titles-font), var(--secondary-font);
  align-self: flex-start;
  font-weight: 400;
  margin: 5vh 0 0 2%;
  font-size: 1.3em;
  height: fit-content;
  width: fit-content;
}

.container-future-events {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 3% 0 0 0;
  justify-content: flex-start;
}

.container-events-infos {
  font-family: var(--titles-font), var(--secondary-font);
  display: flex;
  align-self: baseline;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
}

.event-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 20vh;
  padding: 0 0 0% 10%;
}

#line {
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-bottom: 10%;
  opacity: 0;
}

#line2 {
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  opacity: 0;
}

#line3 {
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 1px;
  background-color:rgb(0, 0, 0);
  position: relative;
  margin-right: 5%;
  margin-top: 1%;
  opacity: 0;
  display: none;
}


@keyframes growEventLines {
  0% {
    width: 0px;
    height: 1px;
    opacity: 0;
  }
  100% {
    width: 95%;
    height: 1px;
    opacity: 1;
  }
}

.event-dates {
  font-weight: 300;
  opacity: .6;
  font-size: 2em;
  margin-bottom: .5em;
}

.event-name {
  font-weight: 300;
  opacity: .8;
  margin-bottom: .3em;
}

.event-location {
  font-weight: 300;
  opacity: .8;
}

.event-more-infos {
  border: 1px solid black;
  cursor: pointer;
  padding: .5em;
  margin-top: 1.5em;
}

.event-image-container {
  width: 50%;
  display: flex;
  padding-left: 10%;
  justify-content: flex-start;
}

.event-image {
  height: 68vh;
}

/* Front Page Animation */

.animation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

video {
  z-index: 0;
  opacity: 0; 
  transition: opacity 1s ease;
  width: 28vw;
  overflow: hidden;
  max-height: 60vh;
}

.greek-font {
  font-family: 'Gentium Plus', serif;
  text-transform: none;
}

/* Footer */

.footer-container {
  font-size: 1rem;
  position: relative;
  margin-bottom: 2%;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 4% 0 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 4;
}

.home-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 1%;
}

/* Different Sizes */

/* menu */

@media (max-width: 1024px) {
  .home-container {
    scrollbar-gutter: none;
    overflow: hidden;
  }
}

/* Styles for maximum width of 700px */
@media (max-width: 1024px) {

  .App {
    width: 100vw;
  }

  .menu-container {
    width: 100vw;
    height: 100vh;
    
  }

  .main-menu-container {
    margin: 10% 0 25% 0;
  }

  .menu-footer {
    width: 94%;
    margin: 0% 3% 2% 3%;
  }

  video {
    width: 40vw;
    overflow: hidden;
    max-height: 60vh;
  }

  /*Carousel*/

  #carouselheading {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 4% 6% 0 1%;
    margin: 0 2% 0 2%;
  }

  #buttonback {
    font-size: 1.5em;
  }

  #year {
    font-size: 1.5em;
  }

  #divisioncarousel1 {
    padding: 0;
    margin: 0;
  }
  
  #image-carousel {
    position: absolute;
    width: 70vw;
    height: auto;
  }

  #images-container {
    display: flex;
    height: 80vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
  
  #buttons-container {
    display: none;
  }

  #dimensions {
    top: 87%;
    width: 50%;
  }
  
  #numbering {
  bottom: 5vh;
  }

  /* About */

  .about-container {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .about-container::-webkit-scrollbar-thumb {
    transition: background 0.2s ease-in-out;
  }
  
  .main-bio-container {
    margin: 0;
    width: 100%;
    height: 86vh;
    padding: 0 0% 0 1%;
    position: relative;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
  }
  
  .about-image-container {
    width: 90vw;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    position: relative;
  }

  .biography-container {
    justify-content: space-between;
    margin: 5% 0  0 4%;
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .email-container {
    width: fit-content;
    font-size: .75em;
  }
  
  .bio-title-container {
    width: fit-content;
    margin-top: 5%;
    margin-bottom: 3%;
  }
  
  .bio-title { 
    font-size: 1.3em;
  }
  
  .bio-firstInfos {
    font-weight: 500;
    font-size: 1.2em;
    transition: opacity 3.5s ease-in-out, transform 3s ease-in-out;
  }
  
  .infos-conainer {
    font-family: var(--primary-font), var(--secondary-font);
    text-transform: uppercase;
    padding-top: 0;
  }

  .about-exhibitions-container {
    margin-top: 2%;
    display: block;
  }

  .exhibitions-container {
    margin-bottom: 5%;
  }

  .upcoming-events {
    width: 100%;
    margin-bottom: 7%;
  }
  
  #excibition-line-braker{
    margin-top: 10%;
    display: block;
  }

  .exhibitions {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin-top: 5%;
    align-self: flex-end;
    padding-right: 2vw;
    padding-left: 3.5%;
  }

  .menu-buttons {
    margin-top: 7vh;
    font-size: 1.5em;
  }

  /* Events */

  #line {
    display: none;
  }

  #line3 {
    margin-top: 5%;
    margin-bottom: 3%;
    display: block;
  }

  .container-events-infos {
    width: 40%;
    height: 30vh;
  }

  .event-image-container {
    align-self: flex-start;
  }

  .event-infos {
    font-size: 1.2em;
  }

  .event-image {
    height: 40vh;
  }

  .title-future-events  {
    font-size: 2em;
  }
  
  #line2 {
    margin-bottom: 30vh;
  }
}

/* Styles for maximum width of 480px */
@media (max-width: 480px) {

  #excibition-lines4 {
    margin-bottom: 4%;
  }

  .main-menu-container{
    height: 85vh;
    margin: 10% 0 25% 0;
  }

  .footer-container  {
    font-size: .8em;
    margin-bottom: 4%;
  }
  
  #datelines4{
    margin-bottom: 4%;
  }

  #line3 {
    margin-top: 8%;
  }

  #line2 {
    margin-bottom: 33vh;
  }

  .title-future-events  {
    font-size: 1.5em;
  }

  .event-image {
    height: 30vh;
  }

  .event-infos {
    font-size: .6em;
  }
  
  .about-image-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    position: relative;
  }
  
  .main-bio-container {
    margin: 0;
    width: 100%;
    height: 100vh;
    padding: 0 0% 0 3%;
    position: relative;
  }

  .email-container {
    margin-top: 10%;
  }

  .infos-conainer {
    margin-top: 10%;
  }

  .biography-container {
    margin-top: 10%;
  }

  .menu-division-container {
    height: fit-content;
  }

  .home-container {
    overflow: hidden;
  }

  #paintingscontainer {
    margin-top: 7vh;
  }

  #imagecontainer {
    width: 40vw;
  }

  #image-carousel {
    position: absolute;
    width: 88vw;
    height: auto;
  }

  video {
    width: 40vw;
    overflow: hidden;
    max-height: 60vh;
  }
}
